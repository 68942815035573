import { StaticImage } from 'gatsby-plugin-image';
import InvestopediaLogo from 'images/imageblock/featured/investopedia.inline.svg';
import FinancialReviewLogo from 'images/imageblock/featured/financial-review.inline.svg';
import FinancialObserverLogo from 'images/imageblock/featured/financial-observer.inline.svg';
import TheAustralianLogo from 'images/imageblock/featured/australian.inline.svg';
import NZHeraldLogo from 'images/imageblock/featured/nz-herald.inline.svg';
import StuffLogo from 'images/imageblock/featured/stuff.inline.svg';
import SydneyMorningHeraldLogo from 'images/imageblock/featured/sydney-morning-herald.inline.svg';
import InvestorsBusinessDailyLogo from 'images/imageblock/featured/ibd.inline.svg';

const commonLogos = [
  {
    key: 'financial-review',
    image: <FinancialReviewLogo />,
  },
  {
    key: 'financial-observer',
    image: <FinancialObserverLogo />,
  },
  {
    key: 'investors-business-daily',
    image: <InvestorsBusinessDailyLogo />,
  },
  {
    key: 'investopedia',
    image: <InvestopediaLogo />,
  },
];

const globalLogos = [
  {
    key: 'money-management',
    image: (
      <StaticImage
        src="../../images/imageblock/featured/money-management.png"
        alt="Money Management logo"
      />
    ),
  },
];

const auLogos = [
  {
    key: 'australian',
    image: <TheAustralianLogo />,
  },
  {
    key: 'sydney-morning-herald',
    image: <SydneyMorningHeraldLogo />,
  },
];

const nzLogos = [
  {
    key: 'nz-herald',
    image: <NZHeraldLogo />,
  },
  {
    key: 'stuff',
    image: <StuffLogo />,
  },
];

// featuredGlobal is also used for locales ca, uk, us,
export const featuredGlobal = commonLogos.concat(globalLogos);
export const featuredAu = auLogos.concat(commonLogos);
export const featuredNz = nzLogos.concat(commonLogos);
