import { StaticImage } from 'gatsby-plugin-image';

export const awardsImages = [
  {
    key: 'xero',
    image: (
      <StaticImage
        src="../../images/imageblock/awards/xero-award.png"
        alt="Xero award winner 2014"
      />
    ),
  },
  {
    key: 'finnies',
    image: (
      <StaticImage
        src="../../images/imageblock/awards/finnies-award.png"
        alt="Finnies award winner 2017"
      />
    ),
  },
  {
    key: 'benzinga',
    image: (
      <StaticImage
        src="../../images/imageblock/awards/benzinga-award.png"
        alt="Benzinga finalist 2018"
      />
    ),
  },
  {
    key: 'fintech',
    image: (
      <StaticImage
        src="../../images/imageblock/awards/fintech-award.png"
        alt="Fintech finalist 2017 2018 2019"
      />
    ),
  },
  {
    key: 'wealthtech',
    image: (
      <StaticImage
        src="../../images/imageblock/awards/wealthtech-award.png"
        alt="Wealthtech award winner 2020"
      />
    ),
  },
  {
    key: 'fintech-2020',
    image: (
      <StaticImage
        src="../../images/imageblock/awards/fintech-2020-award.png"
        alt="Fintech awards finalist 2020"
      />
    ),
  },
];
