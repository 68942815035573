import { useTheme } from 'styled-components';

import { useMemo } from 'react';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';
import { Container } from 'components/Container';
import { Box } from 'components/Box';
import { FlexContainer } from 'components/FlexContainer';

import { imageBlockHeader } from './header';
import { imageBlockImages } from './images';

import type { FC } from 'react';
import type { Color } from 'styled/types';

export interface ImageBlockProps {
  variation: 'featured' | 'awards' | 'registries';
  background?: Color;
}

export const ImageBlock: FC<ImageBlockProps> = ({ background, variation }) => {
  const { currentLocale } = useLocalizationContext();
  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery(breakpoints.down('lg'));
  const isMobile = useMediaQuery(breakpoints.down('md'));

  const header = imageBlockHeader[variation];

  const images =
    variation === 'featured'
      ? imageBlockImages.featured[currentLocale.id]
      : imageBlockImages[variation];

  const renderImages = useMemo(
    () =>
      images.map(image => (
        <FlexContainer alignItems="center" key={image.key}>
          <Box paddingLeft={4} paddingRight={4} paddingTop={8} paddingBottom={8}>
            {image.image}
          </Box>
        </FlexContainer>
      )),
    [images]
  );

  return (
    <Container manageLayout={false} background={background}>
      <h2>{header}</h2>
      <FlexContainer
        justifyContent={isTablet ? 'flex-start' : 'space-between'}
        flexWrap={isTablet ? 'wrap' : undefined}
        direction={isMobile ? 'column' : 'row'}
        alignItems="center"
      >
        {renderImages}
      </FlexContainer>
    </Container>
  );
};
