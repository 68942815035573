import { awardsImages } from './awardsImages';
import { featuredAu, featuredGlobal, featuredNz } from './featuredImages';
import { registriesImages } from './registriesImages';

export const imageBlockImages = {
  awards: awardsImages,
  registries: registriesImages,
  featured: {
    au: featuredAu,
    nz: featuredNz,
    global: featuredGlobal,
    us: featuredGlobal,
    uk: featuredGlobal,
    ca: featuredGlobal,
  },
};
