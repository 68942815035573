import { StaticImage } from 'gatsby-plugin-image';

export const registriesImages = [
  {
    key: 'link',
    image: (
      <StaticImage
        src="../../images/imageblock/share-registries/link.png"
        alt="Link Market Services logo"
      />
    ),
  },
  {
    key: 'automic',
    image: (
      <StaticImage
        src="../../images/imageblock/share-registries/automic.png"
        alt="Automic Group logo"
      />
    ),
  },
  {
    key: 'computershare',
    image: (
      <StaticImage
        src="../../images/imageblock/share-registries/computershare.png"
        alt="Computershare logo"
      />
    ),
  },
  {
    key: 'boardroom',
    image: (
      <StaticImage
        src="../../images/imageblock/share-registries/boardroom.png"
        alt="BoardRoom logo"
      />
    ),
  },
  {
    key: 'equiniti',
    image: (
      <StaticImage
        src="../../images/imageblock/share-registries/equiniti.png"
        alt="EQ by Equiniti logo"
      />
    ),
  },
];
